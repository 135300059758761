import { createStore } from '@halka/state';
import englishMessages from 'i18n/i18n.json';
import germanMessages from 'i18n/i18n_de.json';
import { useCallback } from 'react';

/**
 * The enum of supported languages.
 */
export const Language = {
  ENGLISH: 'en',
  GERMAN: 'de',
};

/**
 * Language store to view what is the currently selected language for the UI.
 */
export const useLanguage = createStore(() => {
  if (typeof window === 'undefined') {
    return Language.ENGLISH;
  }

  const langCode = localStorage.getItem('integrtr-help-lang');

  // If some unknown language code is found then use English by default.
  if (Object.values(Language).includes(langCode)) {
    return langCode;
  }
  return Language.ENGLISH;
});

/**
 * Hook to set the current language.
 */
export function useLanguageSetter() {
  const setLanguage = useCallback((lang) => {
    useLanguage.set(lang);
    localStorage.setItem('integrtr-help-lang', lang);
  }, []);

  return setLanguage;
}

/**
 * Get the messages appropriate for the given language.
 */
export function getMessages(language) {
  switch (language) {
    case Language.GERMAN:
      return { ...englishMessages, ...germanMessages };
    default:
      return englishMessages;
  }
}
