import * as Sentry from '@sentry/node';
import { RewriteFrames } from '@sentry/integrations';

import config from './config';

export const initSentry = () => {
  if (config.SENTRY_DSN && config.NODE_ENV === 'production') {
    const integrations = [];

    if (process.env.NEXT_IS_SERVER === 'true' && __dirname) {
      integrations.push(
        new RewriteFrames({
          iteratee: (frame) => {
            frame.filename = frame.filename.replace(__dirname, 'app:///');
            frame.filename = frame.filename.replace('.next', '_next');
            return frame;
          },
        })
      );
    }

    Sentry.init({
      enabled: config.NODE_ENV === 'production',
      dsn: config.SENTRY_DSN,
      release: config.RELEASE,
    });
  }
};
