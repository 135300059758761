import App from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { ApolloProvider } from '@apollo/client';
import { theme, ChakraProvider, extendTheme } from '@chakra-ui/react';
import nookies from 'nookies';
import { IntlProvider } from 'react-intl';
import { getMessages, useLanguage, useLanguageSetter } from 'store/language';
import { fetchWhoAmI, WhoAmIContext } from 'store/user';
import { useApollo } from 'lib/apolloClient';
import { initSentry } from 'lib/sentry';
import useLocalizedRouter from 'components/hooks/useLocalizedRouter';

import 'nprogress/nprogress.css';
import { useEffect } from 'react';

initSentry();

const TopProgressBar = dynamic(
  () => {
    return import('components/TopProgressBar');
  },
  { ssr: false }
);

const customTheme = extendTheme({
  styles: {
    global: {
      'html, body': {
        height: '100%',
        scrollBehavior: 'smooth',
        fontFamily: 'Inter, sans-serif',
      },
      '#__next': {
        height: '100%',
      },
      '#nprogress': {
        '.bar': {
          zIndex: theme.zIndices.popover,
        },
        '.spinner': {
          zIndex: theme.zIndices.popover,
        },
      },
      '.medium-zoom-overlay': {
        zIndex: theme.zIndices.tooltip,
      },
      '.medium-zoom-image--opened': {
        zIndex: theme.zIndices.tooltip,
      },
      '@media print': {
        '[data-print-hidden]': {
          display: 'none',
        },
      },
    },
  },
});

export default function MyApp({ Component, pageProps, err }) {
  const apolloClient = useApollo(pageProps.initialApolloState);
  const language = useLanguage();
  const setLanguage = useLanguageSetter();
  const router = useLocalizedRouter();

  useEffect(() => {
    const selectedLanguage = router.query.lang;
    const _language = selectedLanguage || language;

    setLanguage(_language);

    router.push(
      {
        pathname: router.pathname,
        query: { ...router.query, lang: _language },
      },
      undefined,
      { shallow: true }
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <IntlProvider locale={language} defaultLocale="en" messages={getMessages(language)}>
      <TopProgressBar />
      <ApolloProvider client={apolloClient}>
        <ChakraProvider resetCSS theme={customTheme}>
          <Head>
            <title>INTEGRTR Help</title>
            <link rel="icon" href="/favicon.ico" />
            <link rel="preconnect" href="https://fonts.gstatic.com" />
            <link
              href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap"
              rel="stylesheet"
            />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
          </Head>
          <WhoAmIContext.Provider value={pageProps.user}>
            <Component {...pageProps} err={err} />
          </WhoAmIContext.Provider>
        </ChakraProvider>
      </ApolloProvider>
    </IntlProvider>
  );
}

MyApp.getInitialProps = async (appContext) => {
  const cookies = nookies.get(appContext.ctx);
  const token = cookies?.basicToken ?? cookies.ssoToken;
  const user = await fetchWhoAmI(token);

  appContext.ctx.user = user;
  const appProps = await App.getInitialProps(appContext);
  return { ...appProps, pageProps: { ...appProps.pageProps, user } };
};
