const config = {
  // GraphQL api endpoint.
  GRAPHQL_API: process.env.NEXT_PUBLIC_GRAPHQL_API,
  // dev or staging or production.
  PIPELINE_ENV: process.env.PIPELINE_ENV,
  // Whether the build is in development or production mode.
  // Automatically injected by NextJS.
  NODE_ENV: process.env.NODE_ENV,
  // The token with which to hit the platform's APIs during development.
  // Do not use this in production.
  AUTH_TOKEN: process.env.NEXT_PUBLIC_AUTH_TOKEN,
  // sentry DSN
  SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
  // is server or not
  IS_SERVER: process.env.NEXT_IS_SERVER,
  // sentry release
  RELEASE: process.env.NEXT_PUBLIC_RELEASE,
  // The base url for the API.
  API_BASE_URL: getApiBaseUrl(),
  // The base url of the platform.
  PLATFORM_BASE_URL: getPlatformBaseUrl(),
  // The sanity dataset against which to query data from.
  SANITY_DATASET: getSanityDataset(),
  YOUTUBE_THUMBNAIL_PROXY_URL: process.env.NEXT_PUBLIC_YOUTUBE_THUMBNAIL_PROXY_URL,
};

/**
 * Get the dataset of Sanity used by this app based on the environment.
 */
function getSanityDataset() {
  let dataset = 'dev';

  if (typeof window === 'undefined') {
    dataset = process.env.PIPELINE_ENV;
  }
  // On localhost only use `dev` dataset.
  else if (window.location.hostname !== 'localhost') {
    // On the browser check for the subdomain to know. If the subdomain has `-dev` suffix,
    // its development; if it has '-staging, its staging otherwise production.
    const subdomain = window.location.hostname.split('.').shift();
    const suffix = subdomain.replace('help', '').replace('-', '');
    dataset = suffix ? suffix : 'production';
  }

  return dataset;
}

/**
 * Gets the API base url for the app.
 */
function getApiBaseUrl() {
  if (process.env.NODE_ENV !== 'production') {
    return process.env.NEXT_PUBLIC_API_BASE_URL;
  }

  let suffix = '-dev';

  if (typeof window === 'undefined') {
    suffix = process.env.PIPELINE_ENV === 'production' ? '' : `-${process.env.PIPELINE_ENV}`;
  } else {
    const subdomain = window.location.hostname.split('.').shift();
    suffix = subdomain.replace('help', '');
  }

  return `https://platform${suffix}-api.integrtr.com`;
}

/**
 * Gets the base url for the platform app.
 */
function getPlatformBaseUrl() {
  if (process.env.NODE_ENV !== 'production') {
    return process.env.NEXT_PUBLIC_PLATFORM_BASE_URL;
  }

  let suffix = '-dev';

  if (typeof window === 'undefined') {
    suffix = process.env.PIPELINE_ENV === 'production' ? '' : `-${process.env.PIPELINE_ENV}`;
  } else {
    const subdomain = window.location.hostname.split('.').shift();
    suffix = subdomain.replace('help', '');
  }

  return `https://platform${suffix}.integrtr.com`;
}

export default config;
