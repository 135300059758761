import { createStore } from '@halka/state';
import axios from 'axios';
import config from 'lib/config';
import { createContext, useCallback, useContext } from 'react';
import nookies from 'nookies';

const client = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
  baseURL: `${config.API_BASE_URL}/api`,
});

/**
 * The context which stores the current logged in user.
 */
export const WhoAmIContext = createContext(null);

/**
 * This cache stores the current logged in user fetched from the API on the frontend so that
 * the Who Am I endpoint is not repeatedly hit.
 *
 * Why are we not using this cache as the main store itself (replacing WhoAmIContext)?
 * Because halka does not support initialization from SSR data.
 */
const whoAmICache = createStore(null);

/**
 * Hook to get the currently logged in user.
 */
export function useWhoAmI() {
  return useContext(WhoAmIContext);
}

/**
 * API to get the current logged in user. Get the actual value from useWhoAmI hook.
 */
export async function fetchWhoAmI(token) {
  const authToken = config.NODE_ENV !== 'production' ? config.AUTH_TOKEN : token;

  if (!authToken) {
    return null;
  }

  // Only optimize this fetch on the frontend. So as to preserve repeated API hits on
  // route changes.
  if (typeof window !== 'undefined') {
    const cache = whoAmICache.get();
    if (cache) {
      // There is already a user in the cache, use it.
      return cache;
    }
  }

  try {
    const response = await client.get('/whoAmI', {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    // We only use this cache on the frontend.
    if (typeof window !== 'undefined') {
      whoAmICache.set(response.data);
    }

    return response.data;
  } catch (error) {
    // There is no logged in user.
    return null;
  }
}

function getToken() {
  if (config.NODE_ENV !== 'production') {
    return config.AUTH_TOKEN;
  }

  const cookies = nookies.get();
  return cookies?.basicToken ?? cookies.ssoToken;
}

/**
 * Hook to logout the user.
 */
export function useLogout() {
  const logout = useCallback(async () => {
    try {
      const token = getToken();
      await client.post('/logout', null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      });
      window.location.reload();
    } catch (err) {
      // Do nothing.

      if (config.NODE_ENV !== 'production') {
        console.info(
          'Cannot logout during development because its uses an environment variable to implement login. ' +
            'To logout, remove `NEXT_PUBLIC_AUTH_TOKEN` from the environment and rerun this app.'
        );
      }
    }
  }, []);

  return { logout };
}
