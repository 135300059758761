/**
 * @file
 *
 * This file contains the hook that is used customizing the router to include the language query parameter.
 */

import { useRouter } from 'next/router';

import { useLanguage } from 'store/language';

export default function useLocalizedRouter() {
  const router = useRouter();
  const originalPush = router.push;

  router.push = (url, as = url, options) => {
    const language = useLanguage.get();

    // If `as` is an object (complex navigation), append `lang` to the query params
    if (typeof as === 'object') {
      as.query = { ...as?.query, lang: language };
    } else {
      // Handle string `as` (simple pathname or hash-based navigation)
      const urlObject = new URL(as, window.location.href);

      // Append the language parameter to the search (query string)
      urlObject.searchParams.set('lang', language);

      // Reconstruct the `as` URL with the pathname, search, and hash
      as = `${urlObject.pathname}${urlObject.search}${urlObject.hash}`;
    }

    return originalPush(url, as, options);
  };

  return router;
}
